import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import IndexPage from "./pages/index";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  });

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#FAFAFA"
      spinnerColor="#9ee5f8"
      textColor="#9ee5f8"
      logoSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/250px-React-icon.svg.png"
      text="Placing some more wood on the fire..."
    >
      <IndexPage />
    </LoadingScreen>
  );
};

export default App;
