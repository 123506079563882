import React from "react";
import { FaAngleDown } from "react-icons/fa";

import styles from "./ScrollDown.module.css";

const ScrollDown = () => (
  <p className={styles.scrolldown}>
    <a href="#about">
      <FaAngleDown />
    </a>
  </p>
);

export default ScrollDown;
