import React, { Component } from "react";

import styles from "./Navigation.module.css";

const isActive = (anchor, pathname) =>
  `${pathname}`.endsWith(anchor) ? styles.current : "";

class Navigation extends Component {
  state = {
    pathname: "#home"
  };
  componentDidMount() {
    window.addEventListener("hashchange", () => {
      this.setState({ pathname: window.location.href });
    });
  }

  render() {
    const { pathname } = this.state;

    return (
      <nav className={styles["nav-wrap"]} id={styles["nav-wrap"]}>
        <a
          className={styles["mobile-btn"]}
          href={`#${styles["nav-wrap"]}`}
          title="Show Navigation"
        >
          Show Navigation
        </a>
        <a
          className={styles["mobile-btn"]}
          href="#home"
          title="Hide Navigation"
        >
          Hide Navigation
        </a>
        <ul id={styles.nav} className={styles.nav}>
          <li className={isActive("#home", pathname)}>
            <a href="#home">Home</a>
          </li>
          <li className={isActive("#about", pathname)}>
            <a href="#about">About</a>
          </li>
          <li className={isActive("#cv", pathname)}>
            <a href="#cv">Experience</a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
