import React from "react";
import classNames from "classnames";
import SocialLink from "./SocialLink";

import styles from "./SocialLinks.module.css";

const SocialLinks = props => {
  return (
    <ul
      className={classNames(
        props.listStyle === "social" ? styles.social : styles["social-links"]
      )}
    >
      {props.networks.map(network => (
        <SocialLink key={network.name} url={network.url} type={network.type} />
      ))}
    </ul>
  );
};

export default SocialLinks;
