import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import Banner from "./Banner";
import Navigation from "./Navigation";
import ScrollDown from "./ScrollDown";

import styles from "./index.module.css";

const Header = props => {
  return (
    <ScrollableAnchor id={"home"}>
      <header className={styles.header}>
        <Navigation />
        <Banner
          city={props.data.address.city}
          description={props.data.description}
          name={props.data.name}
          occupation={props.data.occupation}
          networks={props.data.social}
        />
        <ScrollDown />
      </header>
    </ScrollableAnchor>
  );
};

export default Header;
