import React from "react";
import classNames from "classnames";
import ReactFitText from "react-fittext";

import { SocialLinks } from "../SocialLinks";

import styles from "./Banner.module.css";
import globalStyles from "../../assets/global-styles/global-styles.module.css";

const Banner = props => (
  <div className={classNames(globalStyles.row, styles.banner)}>
    <div className={styles["banner-text"]}>
      <ReactFitText minFontSize={40} maxFontSize={65}>
        <h1>I am {props.name}</h1>
      </ReactFitText>
      <h3>
        I am a {props.city} based <span>{props.occupation}</span>.{" "}
        {props.description}
      </h3>
      <hr />
      <SocialLinks networks={props.networks} listStyle="social" />
    </div>
  </div>
);

export default Banner;
