import React from "react";

import { FaGithub, FaLinkedinIn, FaTwitter } from "react-icons/fa";

const SocialLink = props => (
  <li key={props.name}>
    <a href={props.url} target="_blank" rel="noopener noreferrer">
      {props.type === "FaGithub" && <FaGithub />}
      {props.type === "FaLinkedinIn" && <FaLinkedinIn />}
      {props.type === "FaTwitter" && <FaTwitter />}
    </a>
  </li>
);

export default SocialLink;
