import React, { Fragment } from "react";

import Header from "../components/Header";

import data from "../data.json";

const AboutPromise = import("../components/About");
const About = React.lazy(() => AboutPromise);
const FooterPromise = import( "../components/Footer");
const Footer = React.lazy(() => FooterPromise);
const ResumePromise = import("../components/Resume");
const Resume = React.lazy(() => ResumePromise);

const IndexPage = () => (
  <Fragment>
    <Header data={data.main} />
    <React.Suspense fallback={<div>Loading...</div>}>
    <About data={data.main} />
    </React.Suspense>
    <React.Suspense fallback={<div>Loading...</div>}>
      <Resume data={data.resume} />
    </React.Suspense>
    <React.Suspense fallback={<div>Loading...</div>}>
    <Footer data={data.main} />
    </React.Suspense>
  </Fragment>
);

export default IndexPage;
